import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs';
import { HttpService } from 'src/app/core/common-service/http.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { AdminLayoutService } from 'src/app/layout/layouts/admin/admin-layout.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  url = environment.APIUrl;
  hotelData: any;
  constructor(private _httpService: HttpService,
    private _adminLayoutService: AdminLayoutService,
    private _utility: UtilityService,) {
    this._adminLayoutService.hotelSubscription.subscribe(data => {
      this.hotelData = data
    })
  }

  getServiceTypes() {
    let params = new HttpParams();
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    params = params.set('partner_business_key', this.hotelData.partner_business_key);
    params = params.set('is_list', 1);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/service/type/getAll?' + params).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getFoodMenuType() {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/food/menu/type/getAll', 'partner').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  checkFoodMenuType() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/food/menu/type/getAll?is_exist=1').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  // Menu types
  getAllFoodMenuTypes(page, isDeleted, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('is_deleted', isDeleted);
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/food/menu/type/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Service Type Start
  // -----------------------------------------------------------------------------------------------------
  getAllServiceTypes(page, limit?) {
    let params = new HttpParams();
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    params = params.set('partner_business_key', this.hotelData.partner_business_key);
    params = params.set('page', page);
    if (limit)
      params = params.set('limit', limit);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/service/type/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  getCommonServiceTypes() {
    let params = new HttpParams();
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    params = params.set('partner_business_key', this.hotelData.partner_business_key);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/service/type/common/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  checkServiceType() {
    let params = new HttpParams();
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    params = params.set('partner_business_key', this.hotelData.partner_business_key);
    params = params.set('is_exist', 1);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/service/type/getAll?' + params).subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  addServiceType(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    data.partner_business_key = this.hotelData.partner_business_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/service/type/addType', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  changeServiceTypeStatus(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    data.partner_business_key = this.hotelData.partner_business_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/service/type/changeStatus', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  deleteServiceType(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    data.partner_business_key = this.hotelData.partner_business_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/service/type/deleteType', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Service Type End
  // -----------------------------------------------------------------------------------------------------


  getAllFoodMenuTypeList(isFilter?) {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    if (isFilter)
      params = params.set('is_filter', 1);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/food/menu/type/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  getCommonFoodMenuTypeList() {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/food/menu/type/common/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  getAllOrderStatusList() {
    return new Promise((resolve, reject) => {
      this._httpService.getPartner('owner/order/status/getAll?is_list=1').subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  addFoodMenuType(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/food/menu/type/addType', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  changeFoodMenuTypeStatus(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    data.partner_business_key = this.hotelData.partner_business_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/food/menu/type/changeStatus', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  deleteFoodMenuType(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    data.partner_business_key = this.hotelData.partner_business_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/food/menu/type/deleteType', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  changeActiveStatus(key) {
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/business/changeStatus', { partner_business_key: key }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  completeSetup(data) {
    data.business_branch_key = this.hotelData.business_branch.business_branch_key;
    data.partner_business_key = this.hotelData.partner_business_key;
    return new Promise((resolve, reject) => {
      this._httpService.postPartner('owner/setup', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  getDashboard() {
    let params = new HttpParams();
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    params = params.set('partner_business_key', this.hotelData.partner_business_key);
    return this._httpService.getPartner('owner/dashboard/getAll?' + params)
  }

  getOrderData() {
    let params = new HttpParams();
    params = params.set('refresh_key', 'order_data');
    params = params.set('business_branch_key', this.hotelData.business_branch.business_branch_key);
    params = params.set('partner_business_key', this.hotelData.partner_business_key);
    return this._httpService.getPartner('owner/dashboard/getAll?' + params).pipe(delay(60000))
  }


}
