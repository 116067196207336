import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'getItemPrice'
})
export class GetItemPrice implements PipeTransform {

  transform(value: string, args: any[]) {
    return this.getItemPrice(args[0], args[1], args[2]) * (args[3] ?? 1);
  }

  getItemPrice(addOnList, variantList, price) {
    let total = 0
    let addonTotal = 0
    addOnList.forEach(addon => {
      addonTotal += addon.unit_price
    });
    let variantTotal = 0
    if (variantList.length > 0) {
      variantList.forEach(variant => {
        variantTotal += variant.unit_price
      });
    }
    total = (addonTotal + variantTotal) + Number(price)
    return total
  }
}
