import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HttpService } from 'src/app/core/common-service/http.service';
import { UserService } from 'src/app/core/user/user.service';
import { MenuLayoutService } from 'src/app/layout/layouts/menu-layout/menu-layout.service';
import { CartServiceService } from 'src/app/services/cart-service.service';

@Injectable({
  providedIn: 'root'
})
export class MenuCardService {
  appendUrl = ''
  menuTypeSlug = ''
  serviceTypeSlug
  partnerName = ''
  cartName;
  tableData;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  userData: any;

  constructor(
    private _httpService: HttpService,
    private _userService: UserService,
    private _cartService: CartServiceService,
  ) {
    // Get current User data
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data

        if (this.menuTypeSlug == 'dine_in' && this.tableData) {
          let name = this.userData.user_login.username + '_' + this.partnerName + '_' + this.menuTypeSlug + '_cart'
          Object.keys(localStorage).forEach((key) => {
            if (key?.includes(name)) {
              if (key.split('_')[0] != this.tableData.number)
                this._cartService.clearCartData(key)
            }
          });
          this.cartName = this.tableData.number + '_' + this.userData.user_login.username + '_' + this.partnerName + '_' + this.menuTypeSlug + '_cart'

        }
        else
          this.cartName = this.userData.user_login.username + '_' + this.partnerName + '_' + this.menuTypeSlug + '_cart'
      })
  }

  /**
   * Resolve menu type
   * @param route
   * @param state
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.appendUrl = route.params.partnerName
    this.partnerName = decodeURIComponent(route.params.partnerName)
    this.tableData = null
    this.serviceTypeSlug = null

    if (route.queryParams['uid']) {
      let uid = route.queryParams['uid']
      this.tableData = JSON.parse(atob(uid))
      this.serviceTypeSlug = this.tableData.service
    }
    else if (route.queryParams['service'])
      this.serviceTypeSlug = route.queryParams['service']

    if (route.params.menuType)
      this.menuTypeSlug = route.params.menuType
    return true
  }

  getFoodMenuTypeList(branchKey) {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    params = params.set('business_branch_key', branchKey);
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + '/food/menu/' + this.menuTypeSlug + '/getDetails' + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : '') + '?' + params, 'user').subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  getMenuItemById(key, branchKey) {
    let params = new HttpParams();
    params = params.set('partners_food_menu_key', key);
    params = params.set('business_branch_key', branchKey);
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + "/food/menu/" + this.menuTypeSlug + "/getByID" + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : '') + '?' + params, 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  getCategoryById(key) {
    let params = new HttpParams();
    params = params.set('partners_food_category_key', key);
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + "/food/category/getByID?" + params, 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  getMenuItems(categoryKey, branchKey, price, foodType, page, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('partners_food_category_key', categoryKey);
    params = params.set('business_branch_key', branchKey);
    params = params.set('page', page);
    if (price)
      params = params.set('price', price);
    if (foodType)
      params = params.set('food_type_drc_key', foodType);
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);

    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + "/food/menu/" + this.menuTypeSlug + "/getAll" + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : '') + '?' + params, 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  getFoodVariantsList() {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + '/food/menu/variant/getAll', 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Order operations
  // -----------------------------------------------------------------------------------------------------

  addOrder(data) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser("order/" + this.menuTypeSlug + "/placeOrder" + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : ''), data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      },
        (error) => {
          reject(error)
        })
    })
  }

  completeOrder(data) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser("order/completePayment", { order_key: data }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      },
        (error) => {
          reject(error)
        })
    })
  }

  verifyOrder(data) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser("order/take_away/verify" + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : ''), data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      },
        (error) => {
          reject(error)
        })
    })
  }

  updateOrder(data) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser("order/" + this.menuTypeSlug + "/updateOrder" + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : ''), data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      },
        (error) => {
          reject(error)
        })
    })
  }

  cancelOrder(data) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser("order/" + this.menuTypeSlug + "/cancelOrder" + (this.serviceTypeSlug ? ('/' + this.serviceTypeSlug) : ''), data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      },
        (error) => {
          reject(error)
        })
    })
  }

  getAllOrders(page, businessKey, branchKey, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    if (businessKey)
      params = params.set('partner_business_key', businessKey);
    if (branchKey)
      params = params.set('business_branch_key', branchKey);
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);
    // if (menuType)
    //   params = params.set('food_menu_type_drc_key', menuType);
    return new Promise((resolve, reject) => {
      this._httpService.getPersonal("order/getAll?" + params).subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  getOrderById(orderKey) {
    return new Promise((resolve, reject) => {
      this._httpService.getPersonal('order/getByID?order_key=' + orderKey).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        //this._error.showError(error);
        reject()
      })
    })
  }

  getOrderStatus(orderKey) {
    let params = new HttpParams();
    params = params.set('order_key', orderKey);
    params = params.set('is_list', 1);
    return new Promise((resolve, reject) => {
      this._httpService.getPersonal('order/status/getAll?' + params).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        //this._error.showError(error);
        reject()
      })
    })
  }

  getCurrentOrderStatus(orderKey) {
    let params = new HttpParams();
    params = params.set('order_key', orderKey);
    return new Promise((resolve, reject) => {
      this._httpService.getPersonal('order/getStatus?' + params).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        //this._error.showError(error);
        reject()
      })
    })
  }

  getCurrentRefundStatus(orderKey) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser('order/checkRefundStatus', { order_key: orderKey }).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getActiveOrder(businessKey, branchKey, serviceKey, uid) {
    let data = {
      partner_business_key: businessKey,
      business_branch_key: branchKey,
      partners_service_table_drc_key: serviceKey,
      table_uid: uid,
    }
    return new Promise((resolve, reject) => {
      this._httpService.postUser('order/checkStatus', data).subscribe((data: any) => {
        data.status_code == 200 ? resolve(data.data) : reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  downloadReceipt(key) {
    let params = new HttpParams();
    params = params.set('order_key', key);
    //params = params.set('is_preview', 1);
    return new Promise((resolve, reject) => {
      this._httpService.getDownloadPersonal("order/getInvoice?" + params).subscribe((data: any) => {
        data ? resolve(data) : reject(data)
      },
        (error) => {
          reject(error)
        })
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Cart operations
  // -----------------------------------------------------------------------------------------------------

  addToCart(item) {
    let cart = this._cartService.getCartData(this.cartName)
    let cartData = {};
    if (cart) {
      cartData = cart
    }
    else {
      cartData['item_list'] = []
    }
    cartData['partner_name'] = this.partnerName;
    cartData['item_list'].push({
      'partners_food_menu_key': item.partners_food_menu_key,
      'partners_food_menu_drc_key': item.partners_food_menu_drc_key,
      'quantity': item.quantity,
      'food_item': item.food_item,
      'food_image': item.food_image,
      'unit_price': item.unit_price,
      'addon_list': item.addon_list ? item.addon_list : [],
      'variant_list': item.variant_list ? item.variant_list : [],
    })
    let total = 0
    cartData['item_list'].forEach(element => {
      total += element.unit_price
    });
    cartData['total'] = total
    this._cartService.setCartData(this.cartName, cartData)
  }

  getCartItems() {
    let cart = this._cartService.getCartData(this.cartName)
    let cartData;
    if (cart) {
      cartData = cart
    }
    return cartData
  }

  removeFromCart(id) {
    let cart = this._cartService.getCartData(this.cartName)?.toString()
    let cartData = {};
    if (cart) {
      cartData = cart
      cartData['item_list'] = cartData['item_list'].filter((data) => data.partners_food_menu_drc_key != id)
    }
    let total = 0
    cartData['item_list'].forEach(element => {
      total += element.unit_price
    });
    cartData['total'] = total
    this._cartService.setCartData(this.cartName, cartData)
  }

  setCartData(data) {
    this._cartService.setCartData(this.cartName, data)
  }

  clearCart() {
    this._cartService.clearCartData(this.cartName)
  }
}
