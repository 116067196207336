export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  env: 'dev',
  APIUrl: 'https://orderplzzbe.sparktechdev.org/api/',
  APIMainUrl: 'https://thesparktech.sparktechdev.org/api/',
  orgURL: 'https://thesparktech.sparktechdev.org',
  domain: 'sparktechdev.org',
  loginDomain: 'https://accounts.sparktechdev.org/',
  myaccountDomain: 'https://myaccount.sparktechdev.org',
  passphrase: '{<^All|//|eB@pp$^>)&[~$T#25218~]',
  emenuDomain: 'https://orderplzz.sparktechdev.org',
  backend: 'https://orderplzzbe.sparktechdev.org',
  appSlug: 'orderplzz',
  appName: 'OrderPlzz!',
  rzp_key: "rzp_test_NnNBGwFyFy1XwQ",
  isDebug: true,
  firebaseConfig: {
    apiKey: "AIzaSyCC74TVyn0gEwBS8KC7wL66hnlRnH3U_5A",
    authDomain: "webtest-1b931.firebaseapp.com",
    projectId: "webtest-1b931",
    storageBucket: "webtest-1b931.appspot.com",
    messagingSenderId: "360830017288",
    appId: "1:360830017288:web:0334c8e7dc63670dabd6bd",
    measurementId: "G-JHZC8ERRT1"
  },
  analyticsId: 'G-JHZC8ERRT1',
  tawkPropertyId: '6507f9ebb1aaa13b7a776bc6',
  tawkWidgetId: '1hajigh5a',
  tableSession: 60,
  appLink: "https://play.google.com/store/apps/details?id=com.spark.orderplzz_staff_app"
};
