/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Modal
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 2.0.0
 *
 */

import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { PartnerService } from 'src/app/components/partner/partner.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';

@Component({
  selector: 'app-setup-modal',
  templateUrl: './setup-modal.component.html',
  styleUrls: ['./setup-modal.component.scss']
})
export class SetupModalComponent implements OnInit {
  foodMenuTypes: any[] = []
  serviceTypes: any[] = []
  allSuggestedServices: any[] = [];
  filteredSuggestedServices: Observable<any[]> | undefined;

  isLoading

  setupForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SetupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private partnerService: PartnerService,
    private _utility: UtilityService,
    private _sparkConfigService: SparkConfigService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    // Menu type form
    this.setupForm = this._fb.group({
      'menu_type_list': this._fb.array([], Validators.required),
      'service_type': [''],
      'service_type_key': [''],
      'language_key': [''],
    })

    // Get menu types
    this.partnerService.getCommonFoodMenuTypeList().then((data: any) => {
      this.foodMenuTypes = data
      for (let type of this.foodMenuTypes) {
        this.getMenuTypeList().push(this._fb.group({
          'menu_type': type?.menu_type,
          'menu_type_slug': type?.menu_type_slug,
          'food_menu_type_key': type.food_menu_type_key
        }))
      }
    })

    // Get service types
    this.partnerService.getCommonServiceTypes().then((data: any) => {
      this.allSuggestedServices = data
      this.filteredSuggestedServices = this.setupForm.get('service_type')?.valueChanges.pipe(
        startWith(''),
        map((serviceType) => {
          return (serviceType ? this._filter(serviceType) : this.allSuggestedServices.slice())
        })
      )
    })

    if (!this.data.isServiceExist) {
      this.setupForm.get('service_type')?.setValidators(Validators.required)
      this.setupForm.get('language_key')?.setValidators(Validators.required)
    }

    // Get language
    this._sparkConfigService.config$.subscribe((config: any) => {
      this.setupForm.get('language_key')?.patchValue(config.partnerLangKey)
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Form methods
  // -----------------------------------------------------------------------------------------------------

  getMenuTypeList(): FormArray {
    return this.setupForm.get('menu_type_list') as FormArray
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Operations
  // -----------------------------------------------------------------------------------------------------

  inputChange() {
    this.setupForm.get('service_type_key')?.patchValue(null)
  }

  optionClicked(serviceType) {
    this.setupForm.get('service_type')?.patchValue(serviceType.service_type)
    this.setupForm.get('service_type_key')?.patchValue(serviceType.service_type_key)
  }

  stepBack() {
    this.setupForm.get('service_type')?.patchValue(null)
    this.setupForm.get('service_type_key')?.patchValue(null)
  }

  private _filter(value): string[] {
    const filterValue = value.toLowerCase();
    return this.allSuggestedServices.filter(serviceType => serviceType.service_type.toLowerCase().includes(filterValue));
  }

  /**
   * Add menu types
   */
  setMenuType(menuType, event) {
    if (event.checked) {
      this.getMenuTypeList().push(this._fb.group({
        'menu_type': menuType.menu_type,
        'menu_type_slug': menuType.menu_type_slug,
        'food_menu_type_key': menuType.food_menu_type_key
      }))
    }
    else {
      let index = this.getMenuTypeList().controls.findIndex((ctrl) => ctrl.get('food_menu_type_key')?.value == menuType.food_menu_type_key)
      this.getMenuTypeList().removeAt(index)
    }

    if (!this.data.isServiceExist) {
      if (event.checked && menuType.menu_type_slug == 'dine_in') {
        this.setupForm.get('service_type')?.setValidators(Validators.required)
        this.setupForm.get('language_key')?.setValidators(Validators.required)
      }
      else {
        this.setupForm.get('service_type')?.removeValidators(Validators.required)
        this.setupForm.get('language_key')?.removeValidators(Validators.required)
      }
    }


    this.setupForm.get('service_type')?.updateValueAndValidity()
    this.setupForm.get('language_key')?.updateValueAndValidity()
  }

  /**
   * Complete Setup
   */
  completeSetup() {
    this.isLoading = true;
    this.partnerService.completeSetup(this.setupForm.value).then((data: any) => {
      this.dialogRef.close('success')
      location.reload()
    }).catch((error) => {
      if (error.status_code == 608) {
        for (var key in error.data) {
          let obj = key.split(".", 3)
          let message = ''
          if (obj[0] == "menu_type_list" && this.getMenuTypeList().length > 0) {
            message = error.data[key][0].replace(key, this.getMenuTypeList().controls[obj[1]].get('menu_type').value)
            this.getMenuTypeList().setErrors({ serverType: { message: this._utility.toProperCase(message) } })
            this.getMenuTypeList().markAllAsTouched()
          }
          // else if (obj[0] == "service_type") {
          //   message = error.data[key][0].replace(key, this.setupForm.get('service_type')?.value)
          //   this.setupForm.get('service_type')?.setErrors({ serverType: { message: this._utility.toProperCase(message) } })
          //   this.setupForm.get('service_type')?.markAllAsTouched()
          // }
          else {
            message = error.data[key][0]
            this.setupForm?.setErrors({ serverType: { message: this._utility.toProperCase(message) } })
            this.setupForm?.markAllAsTouched()
          }
        }
      }
    })
      .finally(() => {
        this.isLoading = false;
      })
  }

  checkMenuType(menuType) {
    return this.getMenuTypeList().controls.findIndex((ctrl) => ctrl.get('food_menu_type_key')?.value == menuType.food_menu_type_key)
  }
}
