import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'properCase'
})
export class ProperCasePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    // Replace with space
    let newStr = value.replaceAll('-', ' ').replaceAll('_', ' ')

    // Capitalize the first letter of each word
    const capitalizedSentences = newStr.split(' ').map(sentence => {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    });

    return capitalizedSentences.join(' ')
  }

}
