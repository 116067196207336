<ng-container *transloco="let t">
  <h2 mat-dialog-title class="mb-0 text-center">{{t('menu_type.title.almost_done_one_more_step')}}</h2>
  <mat-dialog-content>
    <form [formGroup]="setupForm">
      <mat-stepper hide-header [linear]="true" #stepper>
        <!-- Menu type -->
        <mat-step>
          <p class="text-center mt-2 mb-3 font-medium">{{t('menu_type.title.select_your_menu_type')}}</p>
          <ul class="flex justify-around items-center">
            <li class="type" *ngFor="let menuType of foodMenuTypes; let i=index">
              <input type="checkbox" class="hidden m-auto" id="menuType{{i}}" [checked]="checkMenuType(menuType)>=0"
                (change)="setMenuType(menuType, $event.target)">
              <label for="menuType{{i}}">
                <div
                  class="services-item relative rounded-lg cursor-pointer text-center flex flex-col px-6 py-4 shadow-md w-36">
                  <img class="m-auto" src="assets/icons/{{menuType.menu_type_slug}}.png" alt="">
                  <h6 class="mt-3">{{menuType.menu_type}}</h6>
                  <!-- <div [class.disabled]="('manage' | ablePure: 'basic' | async) && menuType.menu_type_slug=='take_away'"
                            class="w-full h-full left-0 top-0 absolute flex items-center justify-center">
                            <mat-icon
                                *ngIf="('manage' | ablePure: 'basic' | async) && menuType.menu_type_slug=='take_away'">
                                lock
                            </mat-icon>
                        </div> -->
                </div>
              </label>
            </li>
          </ul>
          <mat-error class="mt-4 text-center" *ngIf="getMenuTypeList().errors?.serverType">
            {{getMenuTypeList().errors?.serverType.message}}
          </mat-error>
        </mat-step>
        <!-- Service type -->
        <mat-step
          *ngIf="!data.isServiceExist && this.setupForm?.get('menu_type_list')?.value && (this.setupForm?.get('menu_type_list')?.value | findInArray:'menu_type_slug':'dine_in')">
          <p class="text-center mt-2 mb-3 font-medium">{{t('service_type.title.select_your_default_dinein_service')}}
          </p>
          <mat-form-field appearance="outline">
            <input matInput (input)="inputChange()" formControlName="service_type" [matAutocomplete]="auto"
              [placeholder]="t('service_type.title.service_type')" #typeInput>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option (click)="optionClicked(serviceType)"
                *ngFor="let serviceType of filteredSuggestedServices | async" [value]="serviceType.service_type">
                {{ serviceType.service_type }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="this.setupForm.get('service_type')?.errors?.serverType">
              {{this.setupForm.get('service_type')?.errors?.serverType.message}}
            </mat-error>
            <mat-error *ngIf="this.setupForm.get('service_type')?.errors?.required">
              {{t('service_type.validation.please_enter_service_type')}}
            </mat-error>
          </mat-form-field>
          <p class="text-sm text-red-600">{{ t('service_type.message.note5') }}</p>
        </mat-step>
      </mat-stepper>
      <mat-error *ngIf="this.setupForm.errors?.serverType">
        {{this.setupForm.errors?.serverType.message}}
      </mat-error>
    </form>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <!-- Skip -->
    <button mat-button *ngIf="stepper.selectedIndex==0" type="button" class="add-round-button"
      (click)="dialogRef.close()">{{t('menu_type.title.skip_for_now')}}</button>

    <!-- Setup Service Type -->
    <ng-container
      *ngIf="!data.isServiceExist && (this.setupForm?.get('menu_type_list')?.value | findInArray:'menu_type_slug':'dine_in')">
      <ng-container *ngIf="stepper.selectedIndex==1">
        <button mat-button type="button" class="add-round-button" (click)="stepper.previous(); stepBack()">back</button>
        <ng-container [ngTemplateOutlet]="finishBtn"></ng-container>
      </ng-container>
      <button *ngIf="stepper.selectedIndex==0" mat-button [disabled]="getMenuTypeList().controls.length<=0 || isLoading"
        class="success-button add-round-button" cdkFocusInitial (click)="stepper.next()">
        <span *ngIf="!isLoading">{{ t('message.next') }}</span>
        <div *ngIf="isLoading" style="padding: 8px;">
          <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
        </div>
      </button>
    </ng-container>

    <!-- Setup Only Menu Type -->
    <ng-container
      *ngIf="!(this.setupForm?.get('menu_type_list')?.value | findInArray:'menu_type_slug':'dine_in') || data.isServiceExist"
      [ngTemplateOutlet]="finishBtn"></ng-container>

    <!-- Finish -->
    <ng-template #finishBtn><button mat-button
        [disabled]="getMenuTypeList().controls.length<=0 || setupForm.invalid || isLoading"
        class="success-button add-round-button" cdkFocusInitial (click)="completeSetup()">
        <span *ngIf="!isLoading">{{t('menu_type.title.finish')}}</span>
        <div *ngIf="isLoading" style="padding: 8px;">
          <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
        </div>
      </button>
    </ng-template>
  </div>
</ng-container>