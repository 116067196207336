import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, catchError, delay, forkJoin, mergeMap, of, retry, tap } from 'rxjs';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { MenuCardService } from 'src/app/components/menu/menu-card.service';
import { Title } from '@angular/platform-browser';
import { HttpService } from 'src/app/core/common-service/http.service';
import { Ability, AbilityBuilder } from '@casl/ability';
import { HttpParams } from '@angular/common/http';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import * as interval from 'worker-timers';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuLayoutService implements Resolve<any> {
  appendUrl: string;
  hotelData = new BehaviorSubject<any>(null);
  availableSubscription = new BehaviorSubject(null);
  timer: any;

  constructor(
    private menuService: MenuCardService,
    private _utilityService: UtilityService,
    private _translocoService: TranslocoService,
    private titleService: Title,
    private _router: Router,
    private _httpService: HttpService,
    private splashScreenStateService: SplashScreenStateService,
    private ability: Ability
  ) { }

  /**
   * Resolve hotel data
   * @param route
   * @param state
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.appendUrl = route.params.partnerName
    if (route.queryParams.uid) {
      interval.clearInterval(this.timer)
      if (sessionStorage.getItem('tableSession')) {
        let sessionTime = sessionStorage.getItem('tableSession') ?? ''
        let time = moment(Number(sessionTime))
        if (moment().diff(time, 'minutes') > (route.queryParams.tableSession ?
          route.queryParams.tableSession : environment.tableSession)) {
          this.showSwal()
        }
        else
          this.setTableSessionTimer()
      }
      else {
        sessionStorage.setItem('tableSession', moment().valueOf().toString())
        this.setTableSessionTimer()
      }
    }
    return forkJoin([
      this.getHotelById(route.params.partnerName).pipe(
        mergeMap((data: any) => {
          if (data.data) {
            // Displaying splash
            this.splashScreenStateService.start()

            // Setting title and favicon
            this.titleService.setTitle(data.data.business_name + " | OrderPlzz!")
            document.getElementById('appFavicon')?.setAttribute('href', data.data?.business_logo ? data.data?.business_logo : 'assets/favicon.ico');
            data.data['partnerName'] = route.params.partnerName
            if (route.params.menuType)
              data.data.menuType = route.params.menuType

            // Update hotel data
            this.hotelData.next(data.data);

            //this.hotelData.next(data.data)
            this.setAvailibility(data.data.business_branch?.business_hours ? (data.data.business_branch.business_hours.is_active ? true : false) : true)

            //  Set Ability
            this.updateAbility(data.data.level)

          }
          else {
            console.log(data.message);
            this._utilityService.showFailure(this._translocoService.translate('error.error_occured'))
            this.hotelData.error(data.message)
            //if(data.status_code!=612)
            this._router.navigateByUrl('/')
          }
          return of(true)
        }),
        catchError((error): any => {
          console.log(error);
          this._utilityService.showFailure(this._translocoService.translate('error.error_occured'))
          this.hotelData.error(error)
          this._router.navigateByUrl('/')
        })
      )
    ])
  }

  setAvailibility(data) {
    this.availableSubscription.next(data)
  }

  /**
   * Getting hotel by Id
   * @param name Hotel slug
   * @returns
   */
  getHotelById(name) {
    return this._httpService.getPublic("public/" + this.appendUrl + "/hotel/getByID?partner_name=" + name, 'user')
  }

  /**
   * Getting menu type (Dine-in, Take away, Delivery)
   * @returns
   */
  getMenuTypes() {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + "/food/menu/type/getAll", 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  getServiceTypeList(branchKey) {
    let params = new HttpParams();
    params = params.set('business_branch_key', branchKey);
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + '/service/type/getAll' + '?' + params, 'user').subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  /**
   * Getting categories
   * @returns
   */
  getCategories() {
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + "/food/category/getAll", 'user').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {
          reject(error)
        })
    })
  }

  /**
 * Getting Sub categories
 * @returns
 */
  getSubCategories(key) {
    let params = new HttpParams();
    params = params.set('parent_key', key);
    params = params.set('category_level', 2);
    return new Promise((resolve, reject) => {
      this._httpService.getPublic('public/' + this.appendUrl + "/food/category/getAll?" + params, 'user').subscribe((data: any) => {
        resolve(data.data);

      },
        (error) => {
          reject(error)
        })
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Utility
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setting rules
   * @param data Subscripation level
   */
  private updateAbility(data) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);

    if (data <= 1) {
      can('manage', 'basic')
    }
    else if (data <= 2) {
      can('manage', 'medium')
    }
    else if (data <= 3) {
      can('manage', 'premium')
    }
    this.ability.update(rules);
  }

  setTableSessionTimer() {
    let sessionTime = sessionStorage.getItem('tableSession') ?? ''
    let time = moment(Number(sessionTime))
    this.timer = !this.timer ? interval.setInterval(() => {
      if (moment().diff(time, 'minutes') > environment.tableSession) {
        this.showSwal()
      }
    }, 1000) : null
  }

  showSwal() {
    interval.clearInterval(this.timer)
    Swal.fire({
      title: this._translocoService.translate('user_menu.message.table_session_expired'),
      html: this._translocoService.translate('user_menu.message.please_rescan_table_qr'),
      icon: 'error',
      allowOutsideClick: false
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        let url = new URL(location.href);
        url.searchParams.delete('uid');
        window.location.replace(url)
        sessionStorage.removeItem('tableSession')
      }
    })
  }

  registerUserFCM(token) {
    return new Promise((resolve, reject) => {
      this._httpService.postUser("registerFCM", { user_fcm_token: token }, false).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }
}
